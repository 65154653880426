@font-face {
    font-family: 'bombing';
    font-style: normal;
    font-weight: normal;
    src: local('bombing'), url('assets/fonts/bombing.ttf');
}

@font-face {
    font-family: 'iCiel Gotham Medium';
    font-style: normal;
    font-weight: normal;
    src: local('iCiel Gotham Medium'),
        url('assets/fonts/icielgothammediumregular.woff') format('woff');
}

.chakra-toast {
    font-family: 'iCiel Gotham Medium';
}

/* Navbar Toggler */
.navbar-toggler {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 6px;
    width: 40px;
    height: 40px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    padding: 6px;
    margin-left: 10px;
}
.navbar-toggler:focus {
    outline: none !important;
    box-shadow: none !important;
}

.navbar-toggler span {
    display: block;
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    left: 8px;
    right: 8px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.navbar-toggler span:nth-child(1) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navbar-toggler span:nth-child(2) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navbar-toggler span:nth-child(3) {
    top: 28px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

/* Open */

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 13px;
    left: 12px;
    right: 8px;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 27px;
    left: 12px;
    right: 8px;
}
